.imageUpload{
    position: absolute;
    width: 50%;
    padding: 25px 20px 0px 20px;
    background-color: white; 
    height: 388px;
    margin: auto;
    z-index: 500;
    top:210px;
    overflow: hidden;
    left: 50%;
    box-shadow: 0px 4px 24px 0px #00000014;
    border-radius: 22px;
    -webkit-border-radius: 22px;
    -moz-border-radius: 22px;
    -ms-border-radius: 22px;
    -o-border-radius: 22px;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
}
.backgroudImg{
    background-image: url('/public/upload-img-bg.svg');
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: 100%;
  
}