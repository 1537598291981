
.green-cirlce{
    position: absolute;
    background-color: #16D986;
    width: 20px;
    height: 20px;
    font-size: 12px;
    text-align: center;
    top:8px;
    right: 15px;
    border-radius: 100%;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    -ms-border-radius: 100%;
    -o-border-radius: 100%;
    
}

.basket{
    top:-5px !important;
    right: -8px !important;

}
.purchased{
    top:-8px !important;
    right: -8px !important;
}


.label{
    font-size: 14px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    
}

.label figure{
    width: 22px !important;
    height: 25px !important;
}



/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 37px;
    height: 24px;
  }
  
  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #E8E8E8;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left:3px;
    box-shadow: 0px 1px 1px 0px #00000029;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #01C04D;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(12px);
    -ms-transform: translateX(12px);
    transform: translateX(12px);
    -moz-transform: translateX(12px);
    -o-transform: translateX(12px);
}
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }