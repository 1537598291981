@import url('https://fonts.googleapis.com/css2?family=Signika:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Catamaran:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@500&display=swap');

.welcomeFont{
    font-family: 'Signika', sans-serif;
    font-size: 40px;
    font-weight:900;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    margin-left: 30px;
}

.spanBase{
    display:"block" ;
    font-family: 'Signika';
    font-size: "25px";
    font-weight: 900;
 
    
}

.pFont{
    font-family: 'Space Grotesk', sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: black;
    margin-top: 25px;
}

#hoveLink:hover {
    background-color: black;  
}

#hoveLink .span {
    color:black !important;
    font-weight:"bold" ;
    font-size:"14px";
    font-family:"Catamaran";

}

#hoverLink {
  color:black !important;
  font-weight:"bold" ;
  font-size:"14px";
  font-family:"Catamaran";
}


#hoveLink:hover span{
    color: white !important;  
    font-family:"Catamaran" , sans-serif ;
    font-weight:"bold" ;
    font-size:"14px"
}




@media (max-width: 414px) {
    .welcomeFont{
        /* font-family: 'Signika', sans-serif;
        font-size: 18px;
        font-weight: 500;
        line-height: 12px;
        letter-spacing: 0em; */
        margin-left: 0px;
    }

    /* .spanBase{
        display:"block" ;
        font-family: 'Signika';
        font-size: "15px";
        font-weight: 500;

    } */

    .pFont{
        font-family: 'Space Grotesk', sans-serif;
        font-size: 10px;
        font-weight: 500;
        line-height: 15px;
        letter-spacing: 0.01em;
        color: #24B3EE;
    }
}



.fontShape{
    font-family: 'Signika', sans-serif;
    font-size: 18px;
    font-weight: 700;
    color: #d6d3d3;
    margin-left: 20px;
    text-decoration: none;
}



.btnStyle{
    background-color: #383838;
}

input.form-control::placeholder {
    opacity: .5;
}


.Buttonhover:hover{
    cursor: pointer;
}
.pointer{
  cursor: pointer;
}

.navbar-nav li{
    margin-left: 50px;
    opacity: 0.3;
    font-family: "Signika";
}

.pFontNav{
    font-family: 'Space Grotesk', sans-serif;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #24B3EE;
    
}
.sideBar{

  position: fixed;
}

.badgeInfo{
  bottom: 10px;
  right: 5px;
  display: flex;
  border-radius: 12px;
  position: absolute;
  background-color: #383838;
  z-index: 500 !important;
  color: white;
  text-align: center;
  width: 100px;
  height: 50px;
  display: none;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
}
.badge:hover .badgeInfo{
  display: block!important;

}
@media (max-width: 414px) {
    .pFontNav{
        font-family: 'Space Grotesk', sans-serif;
        font-size: 13px;
        font-weight: 15px;
        line-height: 22px;
        color: #24B3EE;
    }    
}

@media (max-width: 414px) {
    .linksStyleClass{
       width: 200px;
       border: 1px solid rebeccapurple;
    }    
    .card-image{
      height: 240px;
    
      }
}
@media (max-width: 1070px) {
  .sideBar{

    position: relative;
  }
}

/* Define the animation */
@keyframes rotate360 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg); /* Rotate the image 360 degrees */
    }
  }
  
  /* Apply the animation to the image */
  .animated-image {
    animation: rotate360 2s linear infinite; /* Apply the animation to the image */
  }

  .input-group:hover{
    cursor: pointer;
  }

  .pagination-container{
    position: fixed;
  }

 .input-group input[type="search"]:focus {
    box-shadow: none;
    border-color: #E0E0E0; 
  }
  .text-over-fLow{
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .input-group {
    position: relative;
  }

  .input-group .input-group-prepend {
    position: absolute;
  }


  @keyframes moveProgressBar {
    0% {
        width: 0%;
      }
      100% {
        width: 100%;
      }
  }

  .navbar {
    background-color: transparent !important;
  }

  .centered-div {
    width: 80%; /* Set the width of the centered div */
    padding: 20px;
    text-align: left;
    margin-top: 5%;
  }

  .searchBarDiv:active{
    background-color: "white";
  }
  

  .liItems:hover {
    cursor: pointer;
    color: "black";
  }

  
  .histDiv {
    background-color: white;
    border: 1px solid #E0E0E0;
    position: absolute;
    list-style: none;
    z-index: 5;
    top: 39px;
    left: 10px;
    width: 96% !important;
  }

  .hoverCamera:hover{
    cursor: pointer;
  }

  .similarBack:hover{
    cursor: pointer;
 
  }

  .btn_similar{
    width:141px;
    height:35px;
    position: absolute;
    background-color:rgba(27, 27, 20, 0.4);
    border-radius:5px;
    z-index: 5;
    left: 50%;
    transform: translateX(-50%);
    top:270px;
    border: 0px solid transparent;
    color:white;
    -webkit-border-radius:5px;
    -moz-border-radius:5px;
    -ms-border-radius:5px;
    -o-border-radius:5px;
  
    
    
}
.card-image{
  height: 320px;

  }
  
  .card-Basket{
    height: 155px;
  
    }

.maintance_bg{

  background-image: url("../public/maintanceBg.svg") ;
  background-position: center center;
background-repeat: no-repeat;
  background-size: cover;
 height: 100vh;
 width: 100vw;


}