/* Title styles */
.title {
    font-size: 48px;
    font-weight: 600;
}

/* Numbers styles */
.numbers {
    width: 30%;
    margin: auto;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 40px;
    
}

/* Line styles */
.line {
    height: 1px;
    background-color: #DCE0DE;
}

/* Main container styles */
.mainDiv {
    width: 90%;
    margin: auto;
    background-size: contain;
    background-image: url('/public/round-bg.svg');
    background-repeat: no-repeat;
    background-position: center 50px;
}

/* Explore section styles */
.explore {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 20px 40px;
    width: 90%;
    margin: 0px auto;
    background-color: #F3F3F3;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
}

/* Explore button styles */
.exploreBtn {
    width: 20%;
    height: 70px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    cursor: pointer;
    background-color: white !important;
    color: black !important;
    border: 1.5px solid black !important;
    font-weight: 500;
    font-size: 14px;
    border-radius: 12px !important;
    -webkit-border-radius: 12px !important;
    -moz-border-radius: 12px !important;
    -ms-border-radius: 12px !important;
    -o-border-radius: 12px !important;

  
}

.arrow{
    transition:all 0.2s linear 0s ;
    -webkit-transition:all 0.2s linear 0s ;
    -moz-transition:all 0.2s linear 0s ;
    -ms-transition:all 0.2s linear 0s ;
    -o-transition:all 0.2s linear 0s ;
    opacity:  0;
    margin-right: -20px;
}
.icon{
    transition:all 0.2s linear 0s ;
    -webkit-transition:all 0.2s linear 0s ;
    -moz-transition:all 0.2s linear 0s ;
    -ms-transition:all 0.2s linear 0s ;
    -o-transition:all 0.2s linear 0s ;
    display: block;
    margin-left: 16px;
    margin-right: 8px;
}
.exploreBtn:hover .arrow{
   
    opacity:1;
    margin-right: -2px;
   
}
.exploreBtn:hover .icon{
 margin-left: 0px;   
}

/* Section container styles */
.section {
    width: 80%;
    display: flex;
    justify-content: space-between;
    background-image: url('/public/center-BG.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-position-y:top;
}

/* Prediction section styles */
.predict {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 50px 20px;
    margin: 20px 0px;
    background-color: #F3F3F3;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
}
.match {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 50px 20px;
    margin: 20px 0px;
    background-color: #F3F3F3;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
}
.success{
    font-size: 14px;
    position: absolute;
    bottom: -45px;
    
}
/* Prediction button styles */
.predictBtn {
    width: 90%;
    height: 60px;
    opacity: 0.6;
    background-color: white !important;
    color: #161616 !important;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
}
.predictBtnShow {
    width: 90%;
    height: 60px;
   border: 1.5px solid black !important;
    background-color: white !important;
    color: #161616 !important;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
}

/* Coming soon (empty for now) */
.comingSoon {
    position: absolute;
    top: -15px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 124px;
    height: 32px;
    font-size: 14px;
    border-radius: 100px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    -ms-border-radius: 100px;
    -o-border-radius: 100px;
}

.input{
    width: 100%;
    display: block;
    height: 40px;
    border:  0.5px solid rgb(195, 192, 192) !important;
    border-radius: 100px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    -ms-border-radius: 100px;
    -o-border-radius: 100px;
    padding: 16px ;
    padding-right: 108px;
}
.input:focus {
    outline:  0.5px solid rgb(195, 192, 192) !important;
}
.joinBtn{
    position: absolute;
    z-index: 50;
    height: 35px;
    width:100px;
    background-color: #333333 !important;
    top:2px;
    right: 2.5px;
    font-size: 14px !important;
    border-radius: 100px !important;
    -webkit-border-radius: 100px !important;
    -moz-border-radius: 100px !important;
    -ms-border-radius: 100px !important;
    -o-border-radius: 100px !important;
}
.joinBtn:hover{
    background-color: #484848 !important;
}



@media screen and (max-width: 1150px) {
    .title {
        font-size: 24px;
        font-weight: 600;
    }
    .mainDiv{
        width: 100%;
        background-image: none;
    }
    .explore {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        padding: 20px 40px;
        width: 95%;
      
    }
    .exploreBtn{
        width: 100%;
        margin-bottom: 30px;

    }
    .numbers{
        width:90%;
    }

    .section{
        width: 100% !important;
        flex-direction: column;
        background-image: url('/public/center-bg-mobile.svg');
        background-position-y:top;
        margin-bottom: 30px;
    }
    .predict{
        width: 95% !important;
        margin: auto;
        margin-top: 300px;
        padding: 20px 20px;
      
    }
    .match{
        width: 95% !important;
        margin:  auto;
        margin-top: 60px;
        padding: 20px 20px;
      
    }
    .predictBtn {
        width: 98%;
        height: 60px;
        
     
    }





}